
import { Component, Vue } from 'vue-property-decorator'
import { ScenicInfo } from '@/types/scenic'

@Component
export default class Detail extends Vue {
  private info: ScenicInfo = {
    projectName: '',
    scenicIntroduce: '',
    imgList: [],
    videoResource: null
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.scenic.selectScenicIntroduceByProjectId, {
      projectId: this.$route.params.id
    }).then(res => {
      this.info = res || {}
    })
  }
}
